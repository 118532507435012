<template>
    <div
        class="cta-button"
        :class="checkForBackground"
    >
        <ButtonOrange
            :text="props.button.length === 0 ? $page.props.settings.translations.ctaButton.button : props.button"
            @clicked="clicked"
        />
        <p
            class="cta-button__description"
            :class="checkForDescriptionColor"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="cta-button__icon"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
            </svg>
            {{ props.description.length === 0 ? $page.props.settings.translations.ctaButton.description : props.description }}
        </p>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import ButtonOrange from '../buttons/orange/watson-theme-orange-button.vue';

const props = defineProps({
    button: {
        type: String,
        required: false,
        default: '',
    },
    description: {
        type: String,
        required: false,
        default: '',
    },
    backgroundColor: {
        type: String,
        required: false,
        default: null,
    },
    descriptionTextColor: {
        type: String,
        required: false,
        default: null,
    },
    redirectUrl: {
        type: String,
        required: false,
        default: null,
    },
});

function clicked() {
    if (props.redirectUrl) {
        window.location.href = props.redirectUrl;
        return;
    }

    if (window.dataLayer) {
        window.dataLayer.push({ event: 'GaEvent', GaCategory: 'ScrollToTop', GaAction: 'ScrollToTopCLick' });
    }

    // Add 'aassi' parameter to current url
    const aassi = window.location.pathname.split('/').slice(-1)[0];
    if (aassi) {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set('aassi', aassi);
        const newUrl = window.location.origin + window.location.pathname + '?' + queryParams.toString();
        window.history.replaceState({ path: newUrl }, '', newUrl);
    }

    const form = document.getElementById('form');
    const headers = document.getElementsByTagName('header');

    if (form) {
        form.scrollIntoView({ behavior: 'smooth' });
    } else if (headers.length) {
        headers[0].scrollIntoView({ behavior: 'smooth' });
    }
}

const checkForBackground = computed(() => {
    const opacity = checkColorOpacity(props.backgroundColor);
    return opacity === ',0)' ? '' : 'cta-button--background';
});

const checkForDescriptionColor = computed(() => {
    const opacity = checkColorOpacity(props.descriptionTextColor);
    return opacity === ',0)' ? '' : 'cta-button__description--text-color';
});

const checkColorOpacity = color => {
    return color?.slice(-3);
};
</script>

<style lang="scss" scoped>
@use "watson-theme-cta-button";

.cta-button {
  --background: v-bind("props.backgroundColor");
  --description: v-bind("props.descriptionTextColor")
}
</style>
